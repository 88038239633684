import React, { useContext, useState } from "react";
import { SmartToy, ApartmentOutlined, Close } from "@mui/icons-material";
import {
  Grid,
  Stack,
  Typography,
  Button,
  ToggleButton,
  Box,
  Tooltip,
} from "@mui/material";
import { BiBot } from "react-icons/bi";
import { BsRobot } from "react-icons/bs";
import { TbMessageDots } from "react-icons/tb";
import { BiUser } from "react-icons/bi";
import {
  AiOutlineQuestionCircle,
  AiFillInfoCircle,
  AiFillPlusCircle,
  AiFillDelete,
} from "react-icons/ai";
import { TfiHeadphoneAlt, TfiCommentsSmiley } from "react-icons/tfi";
import { Ri24HoursLine } from "react-icons/ri";
import { LuMessagesSquare } from "react-icons/lu";
import Switch from "@mui/material/Switch";

import { MuiColorInput } from "mui-color-input";
import TextFieldNew from "../../components/TextFieldNew";

const WebSDKSettingV2 = ({
  botData,
  setBotData,
  handleSubmitUpdateWebSDK,
  hitAxios,
}) => {
  const [suggestion, setSuggestion] = useState("");
  const [isError, setError] = useState(false);
  async function returnUrl(media) {
    const fd = new FormData();
    fd.append("file", media);
    const res = await hitAxios({
      path: "/api/user/return_media_url",
      post: true,
      admin: false,
      obj: fd,
    });
    if (res.data.success) {
      return res.data.url;
    }
  }
  const handleCompanyLogoChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "image/png") {
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      if (file.size <= maxSize) {
        const url = await returnUrl(file);
        setBotData({ ...botData, companyLogo: url });
      } else {
        alert("Please select file size below 5MB");
      }
    } else {
      setBotData({ ...botData, companyLogo: "" });
      alert("Please select a PNG file.");
    }
  };
  const checkError = (data) => {
    if (
      isNaN(data?.bottomPlacement?.replace("%", "")) ||
      !data?.bottomPlacement?.includes("%") ||
      (data?.bottomPlacement?.includes("%") &&
        data?.bottomPlacement.length == 1)
    ) {
      setError(true);
    } else if (
      isNaN(data?.rightPlacement?.replace("px", "")) ||
      !data?.rightPlacement?.includes("px") ||
      (data?.rightPlacement?.includes("px") && data?.rightPlacement.length == 2)
    ) {
      setError(true);
    } else if (
      isNaN(data?.rightPlacementMobile?.replace("px", "")) ||
      !data?.rightPlacementMobile?.includes("px") ||
      (data?.rightPlacementMobile?.includes("px") &&
        data?.rightPlacementMobile.length == 2)
    ) {
      setError(true);
    } else if (
      isNaN(data?.bottomPlacementMobile?.replace("px", "")) ||
      !data?.bottomPlacementMobile?.includes("px") ||
      (data?.bottomPlacementMobile?.includes("px") &&
        data?.bottomPlacementMobile.length == 2)
    ) {
      setError(true);
    } else if (
      data?.menuSection.contactUs &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data?.contactUsReceivingEmail)
    ) {
      setError(true);
    } else {
      setError(false);
    }
  };
  return (
    <Grid item xs={12} p={1}>
      <Stack spacing={2}>
        <Typography>Chat bubble icon</Typography>
        <Box
          display={"flex"}
          gap={"10px"}
          flexWrap={"wrap"}
          direction="row"
          spacing={2}
        >
          <ToggleButton
            value={botData?.bubbleIcon}
            selected={botData?.bubbleIcon === "BiBot"}
            onClick={(e) => setBotData({ ...botData, bubbleIcon: "BiBot" })}
          >
            <BiBot style={{ width: "30px", height: "30px" }} />
          </ToggleButton>
          <ToggleButton
            value={botData?.bubbleIcon}
            selected={botData?.bubbleIcon === "BsRobot"}
            onClick={() =>
              setBotData({
                ...botData,
                bubbleIcon: "BsRobot",
              })
            }
          >
            <BsRobot style={{ width: "30px", height: "30px" }} />
          </ToggleButton>
          <ToggleButton
            value={botData?.bubbleIcon}
            selected={botData?.bubbleIcon === "TbMessageDots"}
            onClick={() =>
              setBotData({ ...botData, bubbleIcon: "TbMessageDots" })
            }
          >
            <TbMessageDots style={{ width: "30px", height: "30px" }} />
          </ToggleButton>
          <ToggleButton
            value={botData?.bubbleIcon}
            selected={botData?.bubbleIcon === "BiUser"}
            onClick={() => setBotData({ ...botData, bubbleIcon: "BiUser" })}
          >
            <BiUser style={{ width: "30px", height: "30px" }} />
          </ToggleButton>
          <ToggleButton
            value={botData?.bubbleIcon}
            selected={botData?.bubbleIcon === "AiOutlineQuestionCircle"}
            onClick={() =>
              setBotData({
                ...botData,
                bubbleIcon: "AiOutlineQuestionCircle",
              })
            }
          >
            <AiOutlineQuestionCircle
              style={{ width: "30px", height: "30px" }}
            />
          </ToggleButton>
          <ToggleButton
            value={botData?.bubbleIcon}
            selected={botData?.bubbleIcon === "TfiHeadphoneAlt"}
            onClick={() =>
              setBotData({
                ...botData,
                bubbleIcon: "TfiHeadphoneAlt",
              })
            }
          >
            <TfiHeadphoneAlt style={{ width: "30px", height: "30px" }} />
          </ToggleButton>
          <ToggleButton
            value={botData?.bubbleIcon}
            selected={botData?.bubbleIcon === "Ri24HoursLine"}
            onClick={() =>
              setBotData({ ...botData, bubbleIcon: "Ri24HoursLine" })
            }
          >
            <Ri24HoursLine style={{ width: "30px", height: "30px" }} />
          </ToggleButton>
          <ToggleButton
            value={botData?.bubbleIcon}
            selected={botData?.bubbleIcon === "LuMessagesSquare"}
            onClick={() =>
              setBotData({ ...botData, bubbleIcon: "LuMessagesSquare" })
            }
          >
            <LuMessagesSquare style={{ width: "30px", height: "30px" }} />
          </ToggleButton>
          <ToggleButton
            value={botData?.bubbleIcon}
            selected={botData?.bubbleIcon === "TfiCommentsSmiley"}
            onClick={() =>
              setBotData({ ...botData, bubbleIcon: "TfiCommentsSmiley" })
            }
          >
            <TfiCommentsSmiley style={{ width: "30px", height: "30px" }} />
          </ToggleButton>
          <ToggleButton
            value={botData?.bubbleIcon}
            selected={botData?.bubbleIcon === "companyLogo"}
            onClick={() =>
              setBotData({ ...botData, bubbleIcon: "companyLogo" })
            }
          >
            Use My Company logo
            {/* <Switch
              {...{ inputProps: { "aria-label": "Switch demo" } }}
              checked={botData?.askPhone}
              name="askPhone"
              onChange={(e) => {
                setBotData({
                  ...botData,
                  askPhone: e.target.checked,
                });
              }}
            /> */}
          </ToggleButton>
        </Box>
        <Stack direction={"row"} spacing={2}>
          <TextFieldNew
            fullWidth
            value={botData?.botName}
            onChange={(e) =>
              setBotData({ ...botData, botName: e.target.value })
            }
            startIcon={
              <SmartToy
                sx={{
                  color: (t) => t.palette.primary.main,
                  height: 20,
                  width: 20,
                }}
              />
            }
            label="Welcome Heading"
          />
          {/* <TextFieldNew
          fullWidth
          value={botData?.companyName}
          onChange={(e) =>
            setBotData({ ...botData, companyName: e.target.value })
          }
          startIcon={
            <ApartmentOutlined
              sx={{
                color: (t) => t.palette.primary.main,
                height: 20,
                width: 20,
              }}
            />
          }
          label="Company Name *"
        /> */}
        </Stack>
      </Stack>

      <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: 2 }}
          style={{
            border: "1px solid grey",
            padding: "6px",
            borderRadius: "8px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {/* <Box>
            {" "}
            Search
            <Switch
              {...{ inputProps: { "aria-label": "Switch demo" } }}
              checked={botData?.menuSection.search}
              name="search"
              onChange={(e) => {
                setBotData({
                  ...botData,
                  menuSection: {
                    ...botData?.menuSection,
                    search: e.target.checked,
                  },
                });
              }}
            />
          </Box> */}
          <Box>
            {" "}
            Conversation
            <Switch
              {...{ inputProps: { "aria-label": "Switch demo" } }}
              checked={botData?.menuSection.conversation}
              name="conversation"
              onChange={(e) => {
                setBotData({
                  ...botData,
                  menuSection: {
                    ...botData?.menuSection,
                    conversation: e.target.checked,
                  },
                });
              }}
            />
          </Box>
          <Box>
            {" "}
            Whatsapp
            <Switch
              {...{ inputProps: { "aria-label": "Switch demo" } }}
              checked={botData?.menuSection.whatsapp}
              name="whatsapp"
              onChange={(e) => {
                setBotData({
                  ...botData,
                  menuSection: {
                    ...botData?.menuSection,
                    whatsapp: e.target.checked,
                  },
                });
              }}
            />
          </Box>
          <Box>
            {" "}
            Track Order
            <Switch
              {...{ inputProps: { "aria-label": "Switch demo" } }}
              checked={botData?.menuSection.trackOrder}
              name="Track Order"
              onChange={(e) => {
                setBotData({
                  ...botData,
                  menuSection: {
                    ...botData?.menuSection,
                    trackOrder: e.target.checked,
                  },
                });
              }}
            />
          </Box>
          <Box>
            {" "}
            Contact Us
            <Switch
              {...{ inputProps: { "aria-label": "Switch demo" } }}
              checked={botData?.menuSection.contactUs}
              name="Contact Us"
              onChange={(e) => {
                setBotData({
                  ...botData,
                  menuSection: {
                    ...botData?.menuSection,
                    contactUs: e.target.checked,
                  },
                });
                checkError({
                  ...botData,
                  menuSection: {
                    ...botData?.menuSection,
                    contactUs: e.target.checked,
                  },
                });
              }}
            />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <MuiColorInput
            size="small"
            fullWidth
            format="hex"
            InputProps={{
              style: {
                borderRadius: 10,
              },
            }}
            value={botData?.accentColor}
            label="Accent colour"
            onChange={(newValue) =>
              setBotData({ ...botData, accentColor: newValue })
            }
          />
          <TextFieldNew
            fullWidth
            value={botData?.subheading}
            onChange={(e) =>
              setBotData({ ...botData, subheading: e.target.value })
            }
            label="SubHeading"
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <TextFieldNew
            fullWidth
            value={botData?.inputPlaceholder}
            onChange={(e) =>
              setBotData({
                ...botData,
                inputPlaceholder: e.target.value,
              })
            }
            label="Input Box Placeholder"
          />
          <TextFieldNew
            fullWidth
            value={botData?.welcomeMessage}
            onChange={(e) =>
              setBotData({ ...botData, welcomeMessage: e.target.value })
            }
            label="Welcome Message"
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <TextFieldNew
            fullWidth
            value={botData?.bottomPlacement}
            onChange={(e) => {
              setBotData({
                ...botData,
                bottomPlacement: e.target.value,
              });
              checkError({
                ...botData,
                bottomPlacement: e.target.value,
              });
            }}
            error={
              isNaN(botData?.bottomPlacement?.replace("%", "")) ||
              !botData?.bottomPlacement?.includes("%") ||
              (botData?.bottomPlacement?.includes("%") &&
                botData?.bottomPlacement.length == 1)
            }
            label="Desktop Bottom Placeholder"
            helperText={"Example : 2%"}
          />
          <TextFieldNew
            fullWidth
            value={botData?.rightPlacement}
            onChange={(e) => {
              setBotData({
                ...botData,
                rightPlacement: e.target.value,
              });
              checkError({
                ...botData,
                rightPlacement: e.target.value,
              });
            }}
            error={
              isNaN(botData?.rightPlacement?.replace("px", "")) ||
              !botData?.rightPlacement?.includes("px") ||
              (botData?.rightPlacement?.includes("px") &&
                botData?.rightPlacement.length == 2)
            }
            label="Desktop Right Placeholder"
            helperText={"Example : 20px"}
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <TextFieldNew
            fullWidth
            value={botData?.bottomPlacementMobile}
            onChange={(e) => {
              setBotData({
                ...botData,
                bottomPlacementMobile: e.target.value,
              });
              checkError({
                ...botData,
                bottomPlacementMobile: e.target.value,
              });
            }}
            error={
              isNaN(botData?.bottomPlacementMobile?.replace("px", "")) ||
              !botData?.bottomPlacementMobile?.includes("px") ||
              (botData?.bottomPlacementMobile?.includes("px") &&
                botData?.bottomPlacementMobile.length == 2)
            }
            label="Mobile Bottom Placeholder"
            helperText={"Example : 20px"}
          />
          <TextFieldNew
            fullWidth
            value={botData?.rightPlacementMobile}
            onChange={(e) => {
              setBotData({
                ...botData,
                rightPlacementMobile: e.target.value,
              });
              checkError({
                ...botData,
                rightPlacementMobile: e.target.value,
              });
            }}
            error={
              isNaN(botData?.rightPlacementMobile?.replace("px", "")) ||
              !botData?.rightPlacementMobile?.includes("px") ||
              (botData?.rightPlacementMobile?.includes("px") &&
                botData?.rightPlacementMobile.length == 2)
            }
            label="Mobile Right Placeholder"
            helperText={"Example : 20px"}
          />
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: 2 }}
          display={botData?.menuSection.contactUs ? "" : "none"}
        >
          <TextFieldNew
            fullWidth
            value={botData?.contactUsReceivingEmail}
            placeholder="xyz@xyz.com"
            onChange={(e) => {
              setBotData({
                ...botData,
                contactUsReceivingEmail: e.target.value,
              });
              checkError({
                ...botData,
                contactUsReceivingEmail: e.target.value,
              });
            }}
            error={
              !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                botData?.contactUsReceivingEmail
              )
            }
            label="Contact Us Receiving Email "
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Typography>Company Logo</Typography>
          <div>
            {botData?.companyLogo ? (
              <div
                style={{
                  border: "3px dashed black",
                  padding: "5px",
                  borderRadius: "10px",
                  position: "relative",
                }}
              >
                <Close
                  onClick={() => setBotData({ ...botData, companyLogo: "" })}
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    cursor: "pointer",
                  }}
                />
                <img
                  src={botData?.companyLogo}
                  alt="Uploaded"
                  height="100px"
                  width="100px"
                />
              </div>
            ) : (
              <input
                type="file"
                accept="image/png"
                onChange={handleCompanyLogoChange}
              />
            )}
          </div>
        </Stack>
        <Box display={"flex"} alignItems={"center"}>
          Ask for details before chat ?{" "}
          <Switch
            {...{ inputProps: { "aria-label": "Switch demo" } }}
            checked={botData?.askDetailsBeforeChat}
            name="Contact Us"
            onChange={(e) => {
              setBotData({
                ...botData,
                askDetailsBeforeChat: e.target.checked,
              });
            }}
          />
          <Tooltip title="We will not be verifying these values.">
            <AiFillInfoCircle fontSize={"25px"} color="#22a454" />{" "}
          </Tooltip>
        </Box>
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: 2 }}
          display={botData?.askDetailsBeforeChat ? "" : "none"}
          style={{
            border: "1px solid #c8c8c8",
            padding: "6px",
            borderRadius: "8px",
          }}
        >
          <Box>
            {" "}
            Email
            <Switch
              {...{ inputProps: { "aria-label": "Switch demo" } }}
              checked={botData?.askEmail}
              name="askEmail"
              onChange={(e) => {
                setBotData({
                  ...botData,
                  askEmail: e.target.checked,
                });
              }}
            />
          </Box>
          <Box>
            {" "}
            Phone
            <Switch
              {...{ inputProps: { "aria-label": "Switch demo" } }}
              checked={botData?.askPhone}
              name="askPhone"
              onChange={(e) => {
                setBotData({
                  ...botData,
                  askPhone: e.target.checked,
                });
              }}
            />
          </Box>
        </Stack>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap="20px"
        >
          <span style={{ whiteSpace: "nowrap" }}> Chat Suggestions </span>
          <TextFieldNew
            fullWidth
            value={suggestion}
            placeholder="Show me some products"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                if (e.target.value.length != "") {
                  setBotData({
                    ...botData,
                    initialChatsuggestions: [
                      ...botData.initialChatsuggestions,
                      suggestion,
                    ],
                  });

                  setSuggestion("");
                }
              }
            }}
            onChange={(e) => setSuggestion(e.target.value)}
            label="Enter Suggestion"
          />
          <AiFillPlusCircle
            fontSize={"35px"}
            color="#22a454"
            onClick={() => {
              if (suggestion.length != "") {
                setBotData({
                  ...botData,
                  initialChatsuggestions: [
                    ...botData.initialChatsuggestions,
                    suggestion,
                  ],
                });

                setSuggestion("");
              }
            }}
          />
        </Box>
        <Box display={"flex"} flexWrap={"wrap"}>
          {botData?.initialChatsuggestions.map((el, index) => {
            return (
              <Box
                display={"flex"}
                justifyContent={"space-around"}
                border={"1px solid  #c8c8c8"}
                padding={"10px"}
                margin={"2%"}
                gap={"10px"}
                borderRadius={"10px"}
              >
                {el}
                <AiFillDelete
                  onClick={() => {
                    let newArray = [...botData.initialChatsuggestions];
                    newArray.splice(index, 1);
                    setBotData({
                      ...botData,
                      initialChatsuggestions: newArray,
                    });
                  }}
                />
              </Box>
            );
          })}
        </Box>
        <Stack direction="row" spacing={2}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="small"
            disabled={isError}
            style={{ margin: "3, 0, 2", textTransform: "none" }}
            onClick={handleSubmitUpdateWebSDK}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default WebSDKSettingV2;
