import { Box, Button, Dialog, Input, Stack } from '@mui/material'
import React, { useState } from 'react'
import CloseBtn from '../../../../context/CloseBtn'
import EmojiPicker from 'emoji-picker-react';
import { Send } from '@mui/icons-material';

const SendBottomEmoji = ({ data, state, setState }) => {
    const [emoji,setEmoji]=useState([])
 
    return (
        <Dialog
            fullWidth
            PaperProps={{
                style: { borderRadius: 10 }
            }}
            key="7"
            open={state.emojiDialog}
            onClose={() => setState({ ...state, emojiDialog: false })}
        >
            <Box p={2} >
                <Stack mb={2} direction={'row'} justifyContent={'flex-end'}>
                    <CloseBtn
                        onClick={() => setState({ ...state, emojiDialog: false })}
                    />
                </Stack>
                <Box mt={2}>
                    <EmojiPicker style={{ width: "100%" }} onEmojiClick={(e) => {
                        // setState({ ...state, text: `${state.text}${e.emoji}`, emojiDialog: false })
                        setEmoji(prev=>[...prev,e.emoji])
                    }} />
                </Box>
                <Stack spacing={1}>
                    <Input  disabled={emoji.length>0?false:true} value={emoji.join("")} sx={{
                    border:"none",
                    
                    }} onKeyDown={(e)=>{
                    
                        if (e.key === "Backspace" || e.key === "Delete") {
                          const newEmo = [...emoji];
                          newEmo.pop();
                          setEmoji(newEmo);
                        }
                       
                    }}/>
                    <Button
                           onClick={()=>{
                              setState({ ...state, text: `${state.text}${emoji.join("")}`, emojiDialog: false })
                              setEmoji([])
                           }}
                             
                            startIcon={<Send />}
                            variant='contained'
                            sx={{ boxShadow: 0, borderRadius: 2 }}>
                            Send
                        </Button>
                        </Stack>
            </Box>
        </Dialog>
    )
}

export default SendBottomEmoji