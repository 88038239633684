import React, { useEffect } from "react";
import { Settings, Share, AppShortcutOutlined } from "@mui/icons-material";
import PreviewIcon from "@mui/icons-material/Preview";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import CustomToggleButton from "../../components/CustomToggleButton";
import WebSDKSetting from "./WebSDKSetting";
import WebSDKShare from "./WebSDKShare";
import WebSDKSettingV2 from "./WebSDKSettingV2";
import { GlobalContext } from "../../context/GlobalContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import Trivia from "./Trivia";
const WebSDK = ({ hitAxios, userData, data }) => {
  const CON = React.useContext(GlobalContext);
  const buttonValues = [
    { value: "Settings", icon: <Settings /> },
    { value: "View", icon: <PreviewIcon /> },
    { value: "Trivia", icon: <PsychologyAltIcon /> },
    { value: "Share", icon: <Share /> },
  ];
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [botData, setBotData] = React.useState();
  const history = useHistory();
  async function getBotsForUser() {
    const res = await hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/bot/" +
        userData?.bots[0]?.botId,
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.status === 200) {
      setBotData(res.data);
      // to be decided or remove this method not required
    }
  }
  useEffect(() => {
    setSelectedOption(buttonValues[0].value);
  }, []);
  const handleSubmitUpdateWebSDK = async () => {
    if (
      botData.botName == "" ||
      botData.bottomPlacement == "" ||
      botData.bottomPlacementMobile == "" ||
      botData.rightPlacement == "" ||
      botData.rightPlacementMobile == "" ||
      botData.accentColor == "" ||
      botData.subheading == "" ||
      botData.welcomeMessage == ""
    ) {
      CON.setData({
        ...CON.data,
        snack: true,
        snack_msg: "Required Parameter is Missing !",
        snack_success: false,
      });
      setTimeout(() => {
        CON.setData({
          ...CON.data,
          snack: false,
          snack_msg: "",
          snack_success: false,
        });
      }, 3000);
      return;
    }
    const res = await hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL + "/backend/bot/" + botData._id,
      post: false,
      admin: false,
      token_user: "",
      patch: true,
      obj: botData,
    });
    if (res.status === 200) {
      getBotsForUser();
    }
  };
  React.useEffect(() => {
    getBotsForUser();
  }, []);
  return (
    <Box m={3} p={2} bgcolor={"white"} borderRadius={2}>
      <Stack direction={"column"} spacing={2}>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <AppShortcutOutlined sx={{ color: "#7C7C7C" }} />
          <Typography variant="h6" color={"#7C7C7C"} fontWeight={600}>
            Web Sdk
          </Typography>
          <CustomToggleButton
            buttonValues={buttonValues}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </Stack>
        <Grid container p={1}>
          {selectedOption === "Settings" ? (
            <WebSDKSettingV2
              botData={botData}
              setBotData={setBotData}
              hitAxios={hitAxios}
              handleSubmitUpdateWebSDK={handleSubmitUpdateWebSDK}
            />
          ) : selectedOption === "View" ? (
            <Box display="flex" gap="5px">
              <Button
                variant="contained"
                onClick={() => {
                  history.push("user?page=contact-us");
                }}
              >
                {" "}
                Contact Us Query
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  history.push("user?page=leads");
                }}
              >
                {" "}
                Sdk Leads
              </Button>
            </Box>
          ) : selectedOption === "Trivia" ? (
            <Trivia
              botData={botData}
              setBotData={setBotData}
              handleSubmitUpdateWebSDK={handleSubmitUpdateWebSDK}
              data={data}
            />
          ) : (
            <WebSDKShare botId={userData?.bots[0]?.botId} />
          )}
        </Grid>
      </Stack>
    </Box>
  );
};

export default WebSDK;
