import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function CustomToggleButton({
  buttonValues,
  selectedOption,
  setSelectedOption,
}) {
  return (
    <ToggleButtonGroup
      color="primary"
      value={selectedOption}
      exclusive
      onChange={(event, newSelectedOption) => {
        if (newSelectedOption !== null) {
          setSelectedOption(newSelectedOption);
        }
      }
      }
      aria-label="Platform"
      size="small"
    >
      {buttonValues.map((itm) => (
        <ToggleButton key={itm.index} value={itm.value} title={itm.value}>{itm.icon}</ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
