import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Tooltip } from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  box: {
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.3)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      border: "2px solid #72c678",
    },
    "&:hover $icon": {
      opacity: 1,
    },
  },
  icon: {
    opacity: 0,
    transition: "opacity 0.3s",
  },
}));
function ValuesOption({ data, handleFunc, state, listTitle, webhookType }) {
  const classes = useStyles();

  return (
    <>
      {listTitle == data.templetFor && data.status == 1 && (
        <Tooltip
          title={
            data.actualMetaTemplet.status == "APPROVED"
              ? ""
              : `Templet ${data.actualMetaTemplet.status}`
          }
        >
          {" "}
          <Box
            borderRadius={2}
            height={"30px"}
            border="2px solid #e1e1e1"
            bgcolor={
              data.actualMetaTemplet.status == "PENDING"
                ? "#ffc102"
                : data.actualMetaTemplet.status == "REJECTED"
                ? "#b0401a"
                : data.isActive
                ? "#509755"
                : "white"
            }
            padding={"3px"}
            m={"7px"}
            color={
              data.isActive == false &&
              data.actualMetaTemplet.status != "REJECTED"
                ? "black"
                : "white"
            }
            paddingLeft={"10px"}
            className={classes.box}
            onClick={() => {
              handleFunc({
                ...state,
                templetData: {
                  ...data.actualMetaTemplet,
                  templetFor: data.templetFor,
                  isActive: data.isActive,
                  id: data._id,
                  webhookType: webhookType,
                },
                addDialog: true,
              });
            }}
          >
            {data.actualMetaTemplet.name}
            <IconButton className={classes.icon}>
              <RemoveRedEyeIcon fontSize="12px" />
            </IconButton>
          </Box>
        </Tooltip>
      )}
    </>
  );
}

export default ValuesOption;
