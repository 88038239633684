import React from "react";
import {
  DataGrid,
} from "@mui/x-data-grid";
import { Box, IconButton, Tooltip } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { GlobalContext } from "../../context/GlobalContext";

const AgentList = ({ data, setState, state, allgents, getAllagents }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [hide, setHide] = React.useState(
    localStorage.getItem("hide_broadcast_header") ? true : false
  );

  async function delAgent(email) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/agent/deleteAgent",
        post: true,
        admin: false,
        obj: { email },
      });
      if (res.data.success) {
        getAllagents();
      }
    }
  }

  return (
    <div>
      <Box
        mt={2}
        borderRadius={4}
        bgcolor={"white"}
        sx={{
          width: '100%',
          maxWidth: '100%',
          overflow: 'hidden',
          border: 1,
          borderColor: 'divider',
        }}
      >
        {allgents ? (
          <Box
            height={hide ? "76vh" : "48vh"}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <DataGrid
              getRowId={(row) => row._id}
              rows={[...allgents].reverse()}
              columns={[
                {
                  headerName: "Name",
                  field: "firstName",
                  flex: 1,
                  minWidth: 150,
                  renderCell: (dataa) => (
                    <Tooltip title={dataa.row.firstName}>
                      <span>{dataa.row.firstName}</span>
                    </Tooltip>
                  ),
                },
                {
                  headerName: "Email",
                  field: "email",
                  flex: 1,
                  minWidth: 200,
                  renderCell: (dataa) => (
                    <Tooltip title={dataa.row.email}>
                      <span>{dataa.row.email}</span>
                    </Tooltip>
                  ),
                },
                {
                  headerName: "Status",
                  field: "status",
                  flex: 1,
                  minWidth: 120,
                  renderCell: (dataa) => (
                    <Tooltip title={dataa.row.status}>
                      <span>{dataa.row.status}</span>
                    </Tooltip>
                  ),
                },
                {
                  headerName: "Action",
                  field: "action",
                  flex: 1,
                  minWidth: 150,
                  renderCell: (dataa) => (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <IconButton
                        onClick={() => {
                          setState({
                            ...state,
                            addAgentDialog: true,
                            agentData: {
                              ...dataa.row,
                              isViewOnly: true,
                            },
                          });
                        }}
                        color="success"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          delAgent(dataa.row.email);
                        }}
                        color="error"
                      >
                        <DeleteOutline />
                      </IconButton>
                    </Box>
                  ),
                },
              ]}
              pageSize={50}
              rowsPerPageOptions={[50]}
              checkboxSelection={false}
              sx={{
                boxShadow: 0,
                borderRadius: 4,
                border: 0,
                fontSize: 13,
                padding: 2,
                '& .MuiDataGrid-cell': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              }}
            />
          </Box>
        ) : (
          <Box p={2}>No agents available</Box>
        )}
      </Box>
    </div>
  );
};

export default AgentList;
