import {
  Box,
  Button,
  CardActionArea,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import TextFieldNew from "../../../../components/TextFieldNew";
import {
  AbcOutlined,
  AddCircleOutline,
  BrowserUpdatedOutlined,
  Call,
  Close,
  DeleteOutline,
  PermContactCalendarOutlined,
  Person,
  Person2,
  Save,
  SaveOutlined,
  SpeakerNotesOutlined,
  StyleOutlined,
} from "@mui/icons-material";
import { GlobalContext } from "../../../../context/GlobalContext";
import { LoadingButton } from "@mui/lab";

const ConversationSide = ({
  DRAWER_WIDTH,
  state,
  setState,
  INBOX,
  ticketComp,
  chatList,
  getChatList,
  data,
  CON,
  chatConvo,
  screenWidth,
}) => {
  const [notes, setNotes] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const { hitAxios } = React.useContext(GlobalContext);
  const [oneTag, setOneTag] = React.useState("");
  const [con, setCon] = React.useState({
    found: false,
    dialogPhonebook: false,
    phonebook: [],
    contactName: INBOX?.data?.openedChat?.sender_name,
  });

  async function checkContact() {
    const res = await hitAxios({
      path: "/api/user/check_contact",
      post: true,
      admin: false,
      obj: { mobile: INBOX?.data?.openedChat?.sender_mobile },
    });
    if (res.data.success) {
      setCon({
        ...con,
        found: true,
        phonebook: res.data?.phonebook || [],
        contact: res.data.contact || [],
        dialogPhonebook: false,
      });
    } else {
      setCon({
        ...con,
        found: false,
        phonebook: res.data?.phonebook || [],
        dialogPhonebook: false,
      });
    }
  }

  React.useEffect(() => {
    if (INBOX?.data?.openedChat) {
      const notes = INBOX?.data?.openedChat?.chat_note;
      const tags = INBOX?.data?.openedChat?.chat_tags
        ? JSON.parse(INBOX?.data?.openedChat?.chat_tags)
        : [];
      setTags(tags);
      setNotes(notes);
      checkContact();
    }
  }, [INBOX?.data?.openedChat?.chat_id]);

  async function saveNotes() {
    const res = await hitAxios({
      path: "/api/user/save_note",
      post: true,
      admin: false,
      obj: { chatId: INBOX?.data?.openedChat?.chat_id, note: notes },
    });
    if (res.data.success) {
      getChatList();
    }
  }

  async function savetags() {
    const res = await hitAxios({
      path: "/api/user/push_tag",
      post: true,
      admin: false,
      obj: {
        chatId: INBOX?.data?.openedChat?.chat_id,
        tag: oneTag,
      },
    });
    if (res.data.success) {
      setTags([...tags, oneTag]);
      setOneTag("");
      getChatList();
    }
  }

  async function delTag(tag) {
    const res = await hitAxios({
      path: "/api/user/del_tag",
      post: true,
      admin: false,
      obj: {
        chatId: INBOX?.data?.openedChat?.chat_id,
        tag: tag,
      },
    });
    if (res.data.success) {
      const newOne = tags.filter((i) => i !== tag);
      setTags(newOne);
      getChatList();
    }
  }

  async function addContact(phoneBookName, phoneBookId) {
    const res = await hitAxios({
      path: "/api/user/save_contact",
      post: true,
      admin: false,
      obj: {
        phoneBookName,
        phoneBookId,
        contactName: con?.contactName,
        phoneNumber: INBOX?.data?.openedChat?.sender_mobile,
        var1: con.var1,
        var2: con.var2,
        var3: con.var3,
        var4: con.var4,
        var5: con.var5,
      },
    });
    if (res.data.success) {
      checkContact();
      setCon({ ...con, dialogPhonebook: false });
    }
  }

  async function delContact() {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/user/del_contact",
        post: true,
        admin: false,
        obj: { id: con?.contact?.id },
      });
      if (res.data.success) {
        // checkContact();
      }
    }
  }

  return (
    <Drawer
      anchor="right"
      open={state.sideBardDialog}
      onClose={() => setState({ ...state, state, sideBardDialog: false })}
    >
      <Dialog
        fullWidth
        open={con.dialogPhonebook}
        onClose={() => setCon({ ...con, dialogPhonebook: false })}
      >
        <Box sx={{ bgcolor: (t) => t.palette.primary.main }} p={1}>
          <Stack alignItems={"center"} direction={"row"} spacing={2}>
            <IconButton
              onClick={() => setCon({ ...con, dialogPhonebook: false })}
            >
              <Close sx={{ color: "white" }} />
            </IconButton>
            <Typography color={"white"} fontWeight={500}>
              {data.pleaseChoosePhonebook}
            </Typography>
          </Stack>
        </Box>

        <Box mt={2} mr={2} ml={2} p={2} bgcolor={"#F6F6F6"} borderRadius={2}>
          <Stack direction={"column"} spacing={2}>
            <TextFieldNew
              value={con.contactName}
              onChange={(e) => setCon({ ...con, contactName: e.target.value })}
              label={data.enterContactName}
              startIcon={<Person />}
            />
            <TextFieldNew
              disabled
              value={INBOX?.data?.openedChat?.sender_mobile}
              shrink={true}
              label={data.mobileNumber}
              startIcon={<Call />}
            />

            <Stack spacing={2} direction={"row"}>
              <TextFieldNew
                fullWidth
                onChange={(e) => setCon({ ...con, var1: e.target.value })}
                label={data.var1}
                startIcon={<AbcOutlined />}
              />
              <TextFieldNew
                fullWidth
                onChange={(e) => setCon({ ...con, var2: e.target.value })}
                label={data.var2}
                startIcon={<AbcOutlined />}
              />
              <TextFieldNew
                fullWidth
                onChange={(e) => setCon({ ...con, var3: e.target.value })}
                label={data.var3}
                startIcon={<AbcOutlined />}
              />
            </Stack>

            <Stack spacing={2} direction={"row"}>
              <TextFieldNew
                fullWidth
                onChange={(e) => setCon({ ...con, var4: e.target.value })}
                label={data.var4}
                startIcon={<AbcOutlined />}
              />
              <TextFieldNew
                fullWidth
                onChange={(e) => setCon({ ...con, var5: e.target.value })}
                label={data.var5}
                startIcon={<AbcOutlined />}
              />
            </Stack>
          </Stack>
        </Box>
        <Box p={2}>
          <Stack direction={"column"} spacing={2}>
            {con?.phonebook?.map((i, key) => {
              return (
                <Stack
                  alignItems={"center"}
                  key={key}
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                >
                  <Stack direction={"row"} spacing={2}>
                    <PermContactCalendarOutlined />
                    <Typography color={"#666666"} fontWeight={600}>
                      {i?.name}
                    </Typography>
                  </Stack>

                  {CON.data?.loading ? (
                    <CircularProgress size={16} />
                  ) : (
                    <IconButton
                      onClick={() => {
                        addContact(i?.name, i?.id);
                      }}
                    >
                      <Save sx={{ color: (t) => t.palette.primary.main }} />
                    </IconButton>
                  )}
                </Stack>
              );
            })}
          </Stack>
        </Box>
      </Dialog>

      <Box p={2} width={350}>
        {screenWidth < 489 && <Box mb={3}>{ticketComp}</Box>}

        <Box mb={3}>
          <Stack spacing={2} direction={"column"}>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <SpeakerNotesOutlined sx={{ color: "#616161" }} />
                <Typography color={"#616161"} variant="h6" fontWeight={600}>
                  {data.notes}
                </Typography>
              </Stack>

              <LoadingButton
                loading={CON.data?.loading}
                onClick={saveNotes}
                variant="contained"
                startIcon={<SaveOutlined />}
                size="small"
                sx={{ borderRadius: 2, boxShadow: 0, textTransform: "none" }}
              >
                {data.save}
              </LoadingButton>
            </Stack>

            <TextFieldNew
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              multiline
              rows={4}
            />
          </Stack>
        </Box>
        <Divider />

        <Box mt={3} mb={3} p={1} borderRadius={2} bgcolor={"#F5F5F5"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {con.found ? (
              <>
                <Stack direction={"column"}>
                  <Typography color={"#616161"} fontWeight={600}>
                    {data.delContact}
                  </Typography>
                  <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                    <PermContactCalendarOutlined
                      sx={{
                        color: (t) => t.palette.primary.main,
                        height: 16,
                        width: 16,
                      }}
                    />
                    <Typography variant="caption" color={"gray"}>
                      {con?.contact?.name}
                    </Typography>
                  </Stack>
                </Stack>
                <IconButton onClick={delContact} color="error">
                  <DeleteOutline />
                </IconButton>
              </>
            ) : (
              <>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <PermContactCalendarOutlined sx={{ color: "#616161" }} />
                  <Typography color={"#616161"} fontWeight={600}>
                    {data.saveContact}
                  </Typography>
                </Stack>

                <IconButton
                  onClick={() => setCon({ ...con, dialogPhonebook: true })}
                  sx={{ color: (t) => t.palette.primary.main }}
                >
                  <SaveOutlined />
                </IconButton>
              </>
            )}
          </Stack>
        </Box>

        <Divider />

        <Box mb={2} mt={3}>
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <StyleOutlined sx={{ color: "#616161" }} />
              <Typography color={"#616161"} variant="h6" fontWeight={600}>
                {data.tags}
              </Typography>
            </Stack>

            <TextFieldNew
              value={oneTag}
              onChange={(e) => setOneTag(e.target.value)}
              label={data.enter}
            
              endIcon={
                CON.data?.loading ? (
                  <CircularProgress size={16} />
                ) : (
                  <IconButton
                    disabled={oneTag.length>0?false:true}
                    sx={{ color: (t) => t.palette.primary.main }}
                    onClick={savetags}
                  >
                    <AddCircleOutline />
                  </IconButton>
                )
              }
            />
          </Stack>

          <Box flexWrap={"wrap"} mt={3}>
            {tags?.map((i, key) => {
              return (
                <Chip
                  key={key}
                  onDelete={() => delTag(i)}
                  sx={{ mb: 1, mr: 1 }}
                  label={i}
                />
              );
            })}
          </Box>
        </Box>

        {/* <Divider />

                <Box mb={3} mt={3} p={1} borderRadius={2} bgcolor={'#F5F5F5'} >
                    <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                        <Stack direction={'column'}>
                            <Typography fontWeight={600} >{data.exportChat}</Typography>
                            <Typography color={'gray'} variant='caption' >{data.exportChatstoYourDevice}</Typography>
                        </Stack>
                        <BrowserUpdatedOutlined sx={{ color: (t) => t.palette.primary.main }} />
                    </Stack>
                </Box>

                <Divider />

                <Box mt={3} p={0.5} borderRadius={2} sx={{ bgcolor: (t) => t.palette.secondary.light }} >
                    <Stack direction={'column'} alignItems={'center'}>
                        <Typography align='center' color={'white'} fontWeight={600} >{data.importChat}</Typography>
                        <Typography align='center' color={'#F3F3F3'} variant='caption' >{data.importYourExportedChats}</Typography>
                    </Stack>
                </Box>
                <Box mb={3} mt={0.5} p={1} borderRadius={2} bgcolor={'#F5F5F5'}>
                    <CardActionArea sx={{ padding: 4 }} >
                        <Typography color={'gray'} variant='body2' align='center'>{data.selectConvoFile}</Typography>
                    </CardActionArea>
                </Box > */}
      </Box>
    </Drawer>
  );
};

export default ConversationSide;
