import { Box } from "@mui/material";
import React from "react";
import { GlobalContext } from "../../context/GlobalContext";
import ActionNeededOrders from "./actionNeededOrders";
import Configration from "./configration";
import History from "./history";
import { RatingReview } from "../review&rating/rating";

const SideScreen = ({ setMenu, menu, state, setState, data, screenWidth }) => {
  const [templetList, setTempletList] = React.useState([]);
  const { hitAxios } = React.useContext(GlobalContext);

  async function getTemplets() {
    const res = await hitAxios({
      path: "/api/user/get_my_meta_templets",
      post: true,
      admin: false,
      obj: { type: "BROADCAST" },
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setTempletList(res.data.data);
    }
  }

  React.useEffect(() => {
    getTemplets();
  }, []);

  return (
    <Box p={2}>
      {menu.selected === "Configration" && (
        <>
          <Configration data={data} />
        </>
      )}

      {menu.selected === "Action_Needed_Orders" && (
        <ActionNeededOrders data={data} />
      )}

    {menu.selected === "Rating" && (
        <RatingReview />
      )}
      {menu.selected === "History" && (
        <>
          <History data={data} />
        </>
      )}
    </Box>
  );
};

export default SideScreen;
