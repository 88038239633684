import { KeyOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CardActionArea,
  Dialog,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { GlobalContext } from "../../context/GlobalContext";
import AddTempletDialog from "./addTemplet/AddTempletDialog";
import CustomWebhook from "./apiAccess/CustomWebhook";
import TempletList from "./templetList/TempletList";

const SideScreen = ({ setMenu, menu, state, setState, data, screenWidth }) => {
  const [templetList, setTempletList] = React.useState([]);
  const [profileData, setProfileData] = React.useState([]);
  const [getKeyDialog, setGetKeyDialog] = React.useState(false);
  const [profile, setProfile] = React.useState({});
  const { hitAxios, getMyDetails } = React.useContext(GlobalContext);
  const userData = JSON.parse(localStorage.getItem("myData"));

  async function getTemplets() {
    const res = await hitAxios({
      path: "/api/user/get_my_meta_templets",
      post: true,
      admin: false,
      obj: { userId: userData?._id, type: "AUTOMATION" },
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setTempletList(res.data.data);
    }
  }
  // console.log("profileData", getMyDetails);
  async function getMe() {
    if (profileData.length == 0) {
      if (getMyDetails.automationTemplet) {
        setProfileData(getMyDetails);
      }
      // const res = await hitAxios({
      //   path: "/api/user/get_me",
      //   post: true,
      //   admin: false,
      //   obj: {
      //     email: JSON.parse(localStorage.getItem("myData"))?.email,
      //   },
      //   token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
      // });
      // if (res.data.success) {
      //   if (res.data.data.automationTemplet) {
      //     setProfileData(res.data.data);
      //   }
      // }
    }
  }

  async function genAPI() {
    const res = await hitAxios({
      path: "/api/user/generate_api_keys",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      getProfile();
    }
  }

  async function getProfile() {
    // const res = await hitAxios({
    //   path: "/api/user/get_me",
    //   post: true,
    //   admin: false,
    //   obj: {
    //     email: JSON.parse(localStorage.getItem("myData")).email,
    //   },
    //   token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    // });
    // if (res.data.success) {
    //   setProfile({ ...res.data.data });
    // }
  }

  React.useEffect(() => {
    getMe();
    getTemplets();
    getProfile();
  }, []);

  return (
    <Box p={2}>
      {menu.selected === "CREATE_TEMPLET" ? (
        <>
          <AddTempletDialog
            screenWidth={screenWidth}
            setMenu={setMenu}
            menu={menu}
            state={state}
            setState={setState}
            data={data}
            getTemplets={getTemplets}
          />
          {/* <Box
            bgcolor={"white"}
            padding={"10px"}
            fontWeight={"500"}
            fontSize={"16px"}
            borderRadius={"10px"}
            color={"gray"}
            boxShadow={"0px 1px 1px rgba(0, 0, 0, 0.3)"}
          >
            Trigger using the Shopify webhook for{" "}
            <span style={{ color: "green", fontWeight: "600" }}>
              {" "}
              ORDER/UPDATE{" "}
            </span>
            events
          </Box> */}
          <Grid container spacing={2}>
            {getMyDetails?.automationTemplet?.map((el) => {
              if (el && el.status) {
                return (
                  <Grid xs={12} lg={3} sm={6} item>
                    <Box
                      position={"relative"}
                      maxHeight={screenWidth > 899 ? "70vh" : "35vh"}
                      mt={2}
                      borderRadius={2}
                      bgcolor={"#eae9e9"}
                      p={0.5}
                      boxShadow={"0px 5px 10px rgba(0, 0, 0, 0.3)"}
                      overflow={"hidden"}
                      overflowY="scroll"
                    >
                      <TempletList
                        templetList={templetList}
                        setTempletList={setTempletList}
                        getTemplets={getTemplets}
                        setState={setState}
                        state={state}
                        data={data}
                        screenWidth={screenWidth}
                        listTitle={el.templetName}
                        webhookType={el.webhookType}
                        colorTheme={"classes1"}
                      />
                    </Box>
                  </Grid>
                );
              }
            })}
          </Grid>
        </>
      ) : menu.selected == "Custom_Webhook" ? (
        <>
          {" "}
          <Dialog
            maxWidth="lg"
            open={getKeyDialog}
            onClose={() => setGetKeyDialog(false)}
            PaperProps={{
              style: { borderRadius: 10 },
            }}
          >
            <Box borderRadius={2} bgcolor={"white"} m={2} p={2}>
              <Stack alignItems={"center"} direction={"column"} spacing={1}>
                {getMyDetails?.api_key && (
                  <Box sx={{ overflowWrap: "break-word" }}>
                    <CardActionArea
                      onClick={() => {
                        navigator.clipboard
                          .writeText(getMyDetails.api_key)
                          .then(() => {
                            alert(data.apiCopied);
                          });
                      }}
                      sx={{ borderRadius: 2, p: 2 }}
                    >
                      <Typography
                        variant="body2"
                        align="center"
                        fontStyle={"italic"}
                        sx={{ wordBreak: "break-all" }}
                      >
                        {getMyDetails.api_key}
                      </Typography>
                    </CardActionArea>
                  </Box>
                )}
                <Button startIcon={<KeyOutlined />} onClick={genAPI}>
                  {data.genAPIKeys}
                </Button>
              </Stack>
            </Box>
          </Dialog>
          <CustomWebhook
            hitAxios={hitAxios}
            data={data}
            setGetKeyDialog={setGetKeyDialog}
            userData={getMyDetails ? getMyDetails : []}
          />
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default SideScreen;
