import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import { GlobalContext } from '../../context/GlobalContext';



export const RatingReview = React.memo(() => {
  const myData = JSON.parse(localStorage.getItem("myData"));

 const {data,hitAxios} =React.useContext(GlobalContext)
  const [userId, setUserId] = useState(myData?._id)
  const [provider, setProvider] = useState('');
  const [mode, setMode] = useState('create'); // '' (initial), 'create', or 'update'
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [configData, setConfigData] = useState(null);
  useEffect(() => {
    fetchConfig();

  }, []);

  const fetchConfig = async () => {
   
 try{

    const res = await hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/reviews/config/?userId=" +
        userId,
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    
      if (res.status===200) {
        setConfigData(res.data);
        setProvider(res.data.provider);
        setMode('update'); // Set mode to 'update' if configuration exists
        setMessage('');
      } else {
        setConfigData(null);
        setMode('create'); // Set mode to 'create' if no configuration is found
        setMessage('ReviewConfig not found for this userId.');
      }
    }catch(err){
      
      setConfigData(null);
      setMode('create');
    }
    
  };

  const handleSave = async () => {
   
      const method = mode === 'create' ? 'post' : 'put';

      const res = await hitAxios({
        path:
          process.env.REACT_APP_BACKEND_BASE_URL +
          "/backend/reviews/config/?userId=" +
        myData?._id,
        obj:{ userId, provider },
        post: method==='create'?true:false,
        put:method==='put'?true:false,
        patch:false,
        admin: false,
        token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
      });
 
   if(res.status===200){
    setMessage('Configuration saved successfully');
    fetchConfig();
   }
   else{
    setMessage('Something Went Wrong');
   }
 
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4">
        {mode === 'create' ? 'Create Configuration' : 'Update Configuration'}
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {message && (
            <Typography variant="h6" gutterBottom color="error">
              {message}
            </Typography>
          )}
     
          {/* <TextField
            label="User ID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            fullWidth
            margin="normal"
            disabled={loading}
          /> */}
          <TextField
            label="Provider"
            value={provider}
            onChange={(e) => setProvider(e.target.value)}
            fullWidth
            margin="normal"
            disabled={loading}
          />
          {mode === 'create' ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={loading}
            >
              Create
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={loading}
            >
              Update
            </Button>
          )}
        </>
      )}
    </Container>
  );
});
